//
//
//
//
import axios from 'axios';
export default {
  name: 'DeviceRegistration',
  mounted: function mounted() {
    var _this = this;

    var device_id = this.$route.query.deviceId;
    var name = this.$route.query.name;
    var otp = this.$route.query.otp;
    var dn = this.$route.query.dn;

    if (!name && dn) {
      name = Buffer.from(dn, 'base64').toString('utf-8');
    }

    axios.post('/api/bridge/', {
      device_id: device_id,
      otp: otp,
      name: name
    }).then(function () {
      _this.$router.push("/setup/devices?mod=".concat(device_id));
    }).catch(function (error) {
      console.error(error);

      _this.$notify.error({
        title: _this.$t('cloud.title.error'),
        message: _this.$t('cloud.title.request_fail'),
        offset: 30
      });

      _this.$router.push('/');
    });
  }
};